const BaseService = require('@/services/BaseService')

const namespace = 'billing/payment-methods'

const find = (cb, errorCb) => {
  BaseService.get(namespace, {}, cb, errorCb)
}

const create = (body, cb, errorCb) => {
  BaseService.post(namespace, body, cb, errorCb)
}

const remove = (id, cb, errorCb) => {
  BaseService.remove(`${namespace}/${id}`, {}, cb, errorCb)
}

export {
  find,
  create,
  remove
}
