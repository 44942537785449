const BaseService = require('@/services/BaseService')

const namespace = 'billing/checkouts'

const create = (id, body, cb, errorCb) => {
  BaseService.post(`${namespace}/${id}`, body, cb, errorCb)
}

export {
  create,
}
