<template>
	<div v-if="!! price"
		 :class="{ default: price.is_default }"
		 class="plan">
		<div class="title">{{ plan.title }}</div>

		<div class="prices">
			<button type="button"
					v-for="planPrice in prices"
					:class="{ active: !! price && planPrice.id === price.id }"
					:key="`price-${planPrice.id}`"
					@click="priceId = planPrice.id">
				{{ formatLabel(planPrice) }}
			</button>
		</div>

		<div class="price">
			<div class="amount">{{ formatCurrency(formatPrice(price).perMonth, currency) }} </div>

			<div class="interval">
				/ {{ $t('billing.plans.month') }}
			</div>
		</div>

		<div v-if="formatPrice(price).discount > 0"
			 class="discount">
			{{ $t('billing.plans.discount', { percentage: formatPrice(price).discount.toFixed(), interval: formatLabel(price).toLowerCase() }) }}
		</div>

		<div class="description">
			{{ $t('billing.plans.boost') }}
		</div>

		<div class="features">
			<!-- Seo keywords -->
			<div v-if="!! featureValue('seo.keywords')"
				 class="feature">
				<i class="fa fa-check fa-fw" />
				<span>{{ $t(`billing.plans.featureKeywords`, { keywords: featureValue('seo.keywords') }) }}</span>
			</div>

			<div v-for="n in 5"
				 class="feature">
				<i class="fa fa-check fa-fw" />
				<span>{{ $t(`billing.plans.feature${n}`) }}</span>
			</div>
		</div>

		<div class="button">
			<button type="button"
					:disabled="checkoutPlan !== null"
					class="btn btn-primary"
					@click="checkout(price.id)">
				<i v-if="checkoutPlan === price.id"
				   class="fa fa-spinner fa-spin fa-fw mr-2"
				/>
				{{ $t('billing.plans.select') }}
			</button>
		</div>
	</div>
</template>

<script>
import * as CheckoutService from '@/services/billing/CheckoutService'
import {mapGetters} from "vuex";

export default {
	props: {
		plan: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		priceId: null,
		checkoutPlan: null
	}),

	computed: {
		...mapGetters('customer', {
			customer: 'getCustomer'
		}),

		currency() {
			const customer = this.customer
			const fallback = 'DKK'

			if (! customer ||
				! customer.country ||
				! customer.country.currency
			) {
				return fallback
			}

			return customer.country.currency.code
		},

		prices() {
			const plan = this.plan || {}
			const prices = plan.prices || []

			return prices
				.filter(price => price.currency.toLowerCase() === this.currency.toLowerCase())
				.sort((a, b) => {
					return this.formatPrice(a).perMonth > this.formatPrice(b).perMonth
						? 1
						: -1
				})
		},

		price() {
			const prices = ((this.plan || {}).prices || [])
				.filter(price => price.currency.toLowerCase() === this.currency.toLowerCase())

			const fallback = prices.find(price => price.is_default)

			return !! this.priceId
				? prices.find(price => String(price.id) === String(this.priceId))
				: fallback
		}
	},

	methods: {
		featureValue(slug) {
			const feature = ((this.plan || {}).features || []).find(item => item.slug === slug)

			if (! feature) {
				return null
			}

			return feature.value
		},

		checkout(id) {
			if (this.checkoutPlan) {
				return
			}

			const base_url = window.location.origin

			this.checkoutPlan = id

			CheckoutService.create(id, {
				domain: base_url
			}, ({ data }) => {
				this.checkoutPlan = null

				if (! data.url) {
					return
				}

				window.location.href = data.url
			}, () => {
				this.checkoutPlan = null
			})
		},

		formatLabel(price) {
			const translations = {
				year: this.$t('billing.plans.year'),
				month: this.$t('billing.plans.month'),
				week: this.$t('billing.plans.week'),
				day: this.$t('billing.plans.day')
			}

			if (price.interval_count === 1) {
				switch (price.interval) {
					case 'year':
						return this.$t('billing.plans.yearly')

					case 'month':
						return this.$t('billing.plans.monthly')

					case 'week':
						return this.$t('billing.plans.weekly')

					case 'day':
						return this.$t('billing.plans.daily')

					default:
						return price.interval
				}
			}

			if (price.interval_count === 3 && price.interval === 'month') {
				return this.$t('billing.plans.quarterly')
			}

			return this.$t('billing.plans.every', { count: price.interval_count, interval: translations[price.interval] })
		},

		formatCurrency(amount, currency) {
			const formatter = new Intl.NumberFormat(this.$i18n.locale, {
				style: 'currency',
				currency,
				minimumSignificantDigits: 2,
				maximumSignificantDigits: 2,
				minimumFractionDigits: 0,
				maximumFractionDigits: 2
			});

			return formatter.format(amount)
		},

		formatPrice(price) {
			const mappingsToMonth = {
				year: 12,
				month: 1,
				week: 4,
				day: 30.5
			}

			const mostExpensive = this.plan
				.prices
				.filter(price => price.currency.toLowerCase() === this.currency.toLowerCase())
				.reduce(
				(carry, p) => {
					const months = mappingsToMonth[p.interval] * p.interval_count
					const perMonth = p.amount / months / 100

					return perMonth > carry
						? perMonth
						: carry
				},
				0
			)

			const months = mappingsToMonth[price.interval] * price.interval_count
			const perMonth = price.amount / months / 100

			const discount = (1 - (perMonth / mostExpensive)) * 100

			return {
				perMonth,
				discount,
				mostExpensive
			}
		}
	}
}
</script>

<style lang="scss" scoped>
div.plan {
	border: 1px solid #e8e8e8;
	border-radius: 15px;
	padding: 20px;
	height: 100%;
	min-height: 450px;
	max-width: 400px;

	display: flex;
	flex-direction: column;
	gap: 15px;

	> div.prices {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;

		> button {
			color: #5b5b5b;
			padding: 0;
			box-shadow: none;
			outline: 0;
			border: 0;
			border-bottom: 2px solid transparent;
			background-color: transparent;
			font-weight: 500;

			&:hover {
				color: #000;
			}

			&.active {
				color: #000;
				border-color: #000;
			}
		}
	}

	.discount {
		color: #727171;
		font-weight: 500;
		text-decoration: underline;
	}

	> div.title {
		color: #000;
		font-size: 16px;
		font-weight: 600;
	}

	> div.price {
		display: flex;
		align-items: center;
		gap: 10px;

		> div.amount {
			color: #000;
			font-size: 35px;
			font-weight: 900;
			line-height: 100%;
		}

		> div.interval {
			color: #2a2a2a;
			font-size: 15px;
			font-weight: 700;
			line-height: 100%;
			margin-top: 4px;
		}
	}

	> div.description {
		color: #828894;
		font-size: 14px;
		font-weight: 500;
	}

	> div.features {
		display: flex;
		flex-direction: column;
		gap: 6px;
		margin-top: 10px;

		> div.feature {
			display: flex;
			align-items: center;
			gap: 6px;

			font-size: 14px;

			i {
				color: #40c395;
				margin-right: 6px;
			}

			span {
				color: #5c606b;
				font-weight: 400;
			}
		}
	}

	> div.button {
		margin-top: auto;
		padding-top: 50px;

		button {
			width: 100%;
			padding: 12px 25px;
			font-size: 14px;
			font-weight: 600;
			border-radius: 8px;
			-webkit-font-smoothing: antialiased;
			color: #0fb881 !important;
			border: 0;
			opacity: 1;
			background-color: #ecfdf5 !important;
		}
	}

	&.default {
		> div.button {
			button {
				color: #fff !important;
				background-color: #0fb881 !important;
			}
		}
	}
}
</style>
