<template>
	<div class="page">
		<div class="hero">
			<div class="container">
				<div class="text">
					<h1>{{ $t('billing.title') }}</h1>
					<div class="description">{{ $t('billing.description') }}</div>
				</div>
			</div>
		</div>

		<div class="container">
			<div class="plans">
				<div class="headline">
					<h2>{{ $t('billing.selectSubscription') }}</h2>
				</div>

				<div v-if="plans !== null"
					 class="plan-listings">
					<plan v-for="plan in plans"
						  :key="`plan-${plan.id}`"
						  :plan="plans[0]"
					/>
				</div>
			</div>
		</div>

		<div class="divider" />

		<div class="container">
			<div class="subscriptions">
				<div class="headline">
					<h2>{{ $t('billing.subscriptions.headline') }}</h2>
					<div class="subheadline">{{ $t('billing.subscriptions.description') }}</div>
				</div>

				<div class="row">
					<div v-for="subscription in subscriptions"
						 :key="`subscription-${subscription.id}`"
						 class="col-sm-12 col-md-6 col-lg-4 mb-5">
						<div class="subscription"
							 :class="{ ended: !! subscription.endDate && hasEnded(subscription.endDate.date) }">
							<div class="subscription-info">
								<div class="title-wrapper">
									<div class="title">{{ subscription.plan.title }}</div>
									<div class="subtitle">{{ $t('billing.subscriptions.boost') }}</div>
								</div>
								<div class="amount-wrapper">
									<div class="amount">{{ subscription.price }} {{ subscription.currency }}</div>
									<div class="interval">/ {{ formatInterval(subscription) }}</div>
								</div>
							</div>

							<template v-if="!! subscription.endDate">
								<div v-if="! hasEnded(subscription.endDate.date)"
									 class="ends_at">
									{{ $t('billing.subscriptions.expires_at', { date: formatDate(subscription.endDate.date) }) }}
								</div>

								<div v-else
									 class="ends_at">
									{{ $t('billing.subscriptions.expired_at', { date: formatDate(subscription.endDate.date) }) }}
								</div>
							</template>

							<div v-if=" !! subscription.externalId"
								 class="actions">
								<template v-if="! subscription.endDate || ! hasEnded(subscription.endDate.date)">
									<button v-if="!! subscription.endDate"
											type="button"
											:disabled="!! cancelling || !! resuming"
											@click="resume(subscription.id)"
											class="btn upgrade">
										<i v-if="resuming === subscription.id"
										   class="fa fa-spinner fa-spin fa-fw mr-2"
										/>
										{{ $t('billing.subscriptions.resume') }}
									</button>

									<button v-if="! subscription.endDate"
											type="button"
											:disabled="!! cancelling || !! resuming"
											@click="cancel(subscription.id)"
											class="btn cancel">
										<i v-if="cancelling === subscription.id"
										   class="fa fa-spinner fa-spin fa-fw mr-2"
										/>
										{{ $t('billing.subscriptions.cancel') }}
									</button>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="divider" />

		<div class="container">
			<div class="payment-methods">
				<div class="headline">
					<h2>{{ $t('billing.paymentMethods.headline') }}</h2>
					<div class="subheadline">{{ $t('billing.paymentMethods.description') }}</div>
				</div>

				<div class="row">
					<div class="col-sm-12 col-lg-4">
						<div class="default-payment-method">
							<div v-if="paymentMethod === false"
								 class="loader">
								<i class="fa fa-spinner fa-spin fa-fw" />
							</div>

							<div v-else-if="paymentMethod !== null"
								 class="payment-method-card">
								<template v-if="paymentMethod.type === 'card'">
									<strong>{{ paymentMethod.card.brand.toUpperCase() }}</strong>
									<span>**** {{ paymentMethod.card.last4 }}</span>
								</template>

								<div v-else>{{ paymentMethod.type }}</div>

								<div class="mt-3">
									<button type="button"
											:disabled="removing"
											class="btn btn-danger"
											@click="removeCard">
										<i v-if="removing"
										   class="fa fa-spinner fa-spin fa-fw mr-2"
										/>
										{{ $t('billing.paymentMethods.remove') }}
									</button>
								</div>
							</div>

							<div v-else>
								<i>{{ $t('billing.paymentMethods.none') }}</i>
							</div>
						</div>
					</div>

					<div class="col-sm-12 col-lg-8 mt-4 mt-xl-0">
						<div ref="card"></div>

						<button type="button"
								:disabled="confirming"
								@click="confirmSetup"
								class="btn btn-primary mt-3">
							<i v-if="confirming"
							   class="fa fa-spinner fa-spin fa-fw mr-2"
							/>
							{{ $t('billing.paymentMethods.update') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as PlanService from '@/services/billing/PlanService'
import * as PaymentMethodService from '@/services/billing/PaymentMethodService'
import * as SubscriptionService from '@/services/billing/SubscriptionService'
import Plan from "@/app/billing/components/Plan";
import {mapGetters} from "vuex";

const STRIPE_PUBLIC_KEY = 'pk_live_51MyVPRFWa32rJKQeodVQkKKZcBqgzVixbnS76h6EVgV8n1M625jdD3QE7WxHwpwZz1LJKNsthKf7GIUhy01BM8XM00tdrZdEVl'

import moment from "moment";

export default {
	data: () => ({
		stripe: null,
		elements: null,

		paymentMethod: false,

		confirming: false,
		removing: false,

		plans: null,

		subscriptions: [],

		cancelling: null,
		resuming: null
	}),

	computed: {
		...mapGetters('identity', {
			features: 'getFeatures'
		}),

		...mapGetters('customer', {
			customer: 'getCustomer'
		})
	},

	async mounted() {
		this.stripe = window.Stripe(STRIPE_PUBLIC_KEY)

		this.loadPaymentMethod()

		setTimeout(() => {
			this.loadPlans()
			this.loadSubscriptions()
			this.createSetupIntent()
		}, 1000)
	},

	methods: {
		formatDate(date) {
			return moment(date).format('LL')
		},

		hasEnded(date) {
			return moment().isAfter(moment(date))
		},

		formatInterval(subscription) {
			const translations = {
				year: this.$t('billing.plans.year'),
				month: this.$t('billing.plans.month'),
				week: this.$t('billing.plans.week'),
				day: this.$t('billing.plans.day'),
			}

			if (subscription.intervalCount === 1) {
				switch (subscription.interval) {
					case 'year':
					case 'month':
					case 'week':
					case 'day':
						return translations[subscription.interval]

					default:
						return subscription.interval
				}
			}

			if (subscription.intervalCount === 3 && subscription.interval === 'month') {
				return this.$t('billing.plans.quarterly')
			}

			return this.$t('billing.plans.every', {count: subscription.intervalCount, interval: translations[subscription.interval]})
		},

		loadPaymentMethod() {
			return new Promise((resolve, reject) => {
				PaymentMethodService.find(({ data }) => {
					this.paymentMethod = data.paymentMethod

					resolve()
				}, () => {
					reject()
				})
			})
		},

		loadPlans() {
			PlanService.findAll({
				includes: ['features'],

				filter_groups: [{
					filters: [
						{
							key: 'default',
							operator: 'eq',
							value: 0
						},
						{
							key: 'slug',
							operator: 'eq',
							value: 'NULL',
							not: true
						}
					]
				}]
			}, ({ data }) => {
				this.plans = data.rows
			}, () => {

			})
		},

		loadSubscriptions() {
			SubscriptionService.findAll({
				sort: [{
					key: 'created',
					direction: 'DESC'
				}],

				includes: ['plan'],

				filter_groups: [{
					filters: [{
						key: 'customer',
						operator: 'eq',
						value: this.customer.id
					}]
				}]
			}, ({ data }) => {
				this.subscriptions = data.rows
			}, () => {

			})
		},

		createSetupIntent() {
			PaymentMethodService.create({}, ({ data }) => {
				this.elements = this.stripe.elements({
					clientSecret: data.client_secret,

					fonts: [
						{
							cssSrc: 'https://fonts.googleapis.com/css?family=Inter:300,400,500,600'
						}
					]
				})

				const paymentElement = this.elements.create('payment')
				paymentElement.mount(this.$refs.card)
			}, response => {
			})
		},

		async confirmSetup() {
			if (this.confirming) {
				return
			}

			this.confirming = true

			const {error, setupIntent} = await this.stripe.confirmSetup({
				elements: this.elements,
				redirect: 'if_required',
			})

			if (error) {
				this.confirming = false
				// @TODO: Error message
				return
			}

			PaymentMethodService.create({
				payment_method: setupIntent.payment_method,
			}, () => {
				this.loadPaymentMethod().then(() => {
					this.confirming = false
				})
			}, () => {
				this.confirming = false
				// @TODO: Error message
				return
			})
		},

		cancel(id) {
			if (this.cancelling) {
				return
			}

			this.cancelling = id

			SubscriptionService.cancel(
				id,
				{},
				() => {
					this.cancelling = false
					this.loadSubscriptions()
				},
				() => {
					this.cancelling = false
					this.loadSubscriptions()
				})
		},

		resume(id) {
			if (this.resuming) {
				return
			}

			this.resuming = id

			SubscriptionService.resume(
				id,
				{},
				() => {
					this.resuming = false
					this.loadSubscriptions()
				},
				() => {
					this.resuming = false
					this.loadSubscriptions()
				})
		},

		removeCard() {
			if (this.removing) {
				return
			}

			this.removing = true

			PaymentMethodService.remove(this.paymentMethod.id, () => {
				this.loadPaymentMethod().then(() => {
					this.removing = false
				})
			}, () => {
				this.removing = false
			})
		}
	},

	components: {Plan},
}
</script>

<style lang="scss" scoped>
.plans-listings {
	display: flex;
	flex-direction: column;

	> div.plan {
		display: flex;
		flex-direction: column;

		> div.title {
			font-weight: 800;
			font-size: 35px;
			padding: 20px;
			margin-bottom: 10px;
		}

		.prices {
			display: flex;
			flex-wrap: wrap;
			gap: 25px;

			> div.price {
				flex: 1;
				border: 1px solid #e8e8e8;
				border-radius: 15px;
				padding: 20px;
				height: 100%;
				min-height: 450px;

				display: flex;
				flex-direction: column;
				gap: 15px;

				> div.title {
					color: #000;
					font-size: 16px;
					font-weight: 600;
				}

				> div.price {
					display: flex;
					align-items: center;
					gap: 10px;

					> div.amount {
						color: #000;
						font-size: 35px;
						font-weight: 900;
						line-height: 100%;
					}

					> div.interval {
						color: #2a2a2a;
						font-size: 15px;
						font-weight: 700;
						line-height: 100%;
						margin-top: 4px;
					}
				}

				> div.description {
					color: #828894;
					font-size: 14px;
					font-weight: 500;
				}

				> div.features {
					display: flex;
					flex-direction: column;
					gap: 6px;
					margin-top: 10px;

					> div.feature {
						display: flex;
						align-items: center;
						gap: 6px;

						font-size: 14px;

						i {
							color: #40c395;
							margin-right: 6px;
						}

						span {
							color: #5c606b;
							font-weight: 400;
						}
					}
				}

				> div.button {
					margin-top: auto;
					padding-top: 50px;

					button {
						width: 100%;
						padding: 12px 25px;
						font-size: 14px;
						font-weight: 600;
						border-radius: 8px;
						-webkit-font-smoothing: antialiased;
						color: #0fb881 !important;
						border: 0;
						opacity: 1;
						background-color: #ecfdf5 !important;
					}
				}

				&.default {
					> div.button {
						button {
							color: #fff !important;
							background-color: #0fb881 !important;
						}
					}
				}
			}
		}
	}
}

.subscription {
	display: flex;
	flex-direction: column;
	padding: 20px;
	border-radius: 6px;
	background-color: #f4f6f8;
	height: 200px;

	&.ended {
		opacity: .5;
	}

	> div.subscription-info {
		display: flex;
		align-items: flex-start;
		gap: 20px;

		> div.title-wrapper {
			> div.title {
				font-weight: 600;
				font-size: 18px;
			}

			> div.subtitle {
				color: #676e80;
				font-weight: 500;
				font-size: 13px;
			}
		}

		> div.amount-wrapper {
			display: flex;
			align-items: flex-end;

			white-space: nowrap;

			gap: 6px;

			line-height: 100%;

			margin-top: 5px;

			> div.amount {
				color: #000;
				font-size: 18px;
				font-weight: 500;
			}

			> div.interval {
				color: #676e80;
				font-size: 13px;
				font-weight: 500;
				margin-bottom: -2px;
			}
		}
	}

	> div.ends_at {
		color: #da3c3c;
		font-size: 15px;
		font-weight: 500;
		margin-top: 10px;
	}

	> div.actions {
		display: flex;
		align-items: center;
		margin-top: auto;
		padding-top: 15px;

		> .upgrade {
			padding: 12px 18px;
			font-size: 14px;
			font-weight: 600;
			border-radius: 8px;
			-webkit-font-smoothing: antialiased;
			color: #fff !important;
			border: 0;
			width: auto;
			opacity: 1;
			margin-right: 10px;
			background-color: #3455db !important;

			&:disabled {
				opacity: .4;
			}
		}

		> .cancel {
			padding: 12px 0;
			font-size: 14px;
			font-weight: 600;
			border-radius: 8px;
			-webkit-font-smoothing: antialiased;
			color: #3455db !important;
			border: 0;
			width: auto;
			opacity: 1;
			background-color: transparent !important;

			&:hover {
				text-decoration: underline;
			}

			&:disabled {
				opacity: .4;
			}
		}
	}
}

.default-payment-method {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 400px;
	min-height: 110px;
	max-width: 100%;
	padding: 20px;
	border-radius: 6px;
	margin-bottom: 25px;
	background-color: #22293f;
	color: #fff;

	.loader {
		text-align: center;
	}

	.payment-method-card {
		display: flex;
		width: 100%;
		gap: 10px;
		flex-direction: column;

		strong {
			font-weight: 600;
			font-size: 18px;
		}

		span {
			color: #fff;
			font-size: 15px;
			font-weight: 400;
		}

		button {
			padding: 12px 18px;
			font-size: 14px;
			font-weight: 600;
			border-radius: 8px;
			-webkit-font-smoothing: antialiased;
			color: #000 !important;
			border: 0;
			width: auto;
			opacity: 1;
			background-color: #fff !important;
		}
	}
}

.page {
	margin: -30px -15px 0 -15px;
	padding-bottom: 80px;
	background-color: #fff;
}

.hero {
	background-image: url(/images/rocket2.png);
	background-size: cover;
	background-position: center right;

	.text {
		padding: 110px 30px 170px 30px;
		max-width: 600px;

		h1 {
			color: #fff;
			font-size: 34px;
			font-family: 'Roboto Slab', serif;
			font-weight: 800;
		}

		div.description {
			color: #fff;
			font-size: 16px;
			font-weight: 500;
			line-height: 160%;
		}
	}
}

.headline {
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin: 0 0 35px 0;

	h2 {
		color: #000;
		font-size: 16px;
		font-weight: 600;
		font-family: 'Roboto Slab', serif;
		text-align: center;
		margin: 0;
		padding: 0;
	}

	div.subheadline {
		color: #8f8f8f;
		text-align: center;
		font-weight: 400;
		font-size: 13px;
	}
}

.plans {
	position: relative;
	margin-top: -70px;
	padding: 30px 30px 40px 30px;
	background-color: #fff;
	z-index: 2;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.subscriptions,
.payment-methods {
	padding: 30px;
}

.divider {
	width: 100%;
	margin: 40px 0;
	height: 1px;
	background-color: #eee;
}


@media (max-width: 1400px) {
	.hero {
		background-image: none;
		background: rgb(0,82,112);
		background: radial-gradient(circle, rgba(0,82,112,1) 0%, rgba(2,95,125,1) 100%);
	}
}


@media (max-width: 1200px) {
	.hero {
		.text {
			text-align: center;
			margin: 0 auto;

			h1 {
				font-size: 28px;
			}

			div.description {
				font-size: 15px;
			}
		}
	}
}
</style>
